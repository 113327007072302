.div_commonwrapper {
  padding-left: 180px !important;
  padding-right: 180px !important;
}
.div-commonwrapper-top-bottom-p{
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.div_container {
  padding: 0px !important;
}
.span_easy_step {
  color: #FF0000  !important;
}

.bg-image-header-service-window-tint{
  background-image: url("../images/car-detailing.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  /* z-index: 2;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--black) 82%), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: none;
    height: 100%;
    padding-top: 0;
    display: flex;
    position: relative; */
}
.service-hero{
  flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    display: flex;
    position: relative;
    overflow: hidden;

}
.service-hero-bg-image-wrapper{
  display: block;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}
.service-hero-bg-image{
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}


.service-hero-fg{
    z-index: 2;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--black) 82%), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: none;
    height: 100%;
    padding-top: 0;
    display: flex;
    position: relative;
}
.service-hero-heading-container{
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 2.5fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  width: 95%;
  max-width: 1500px;
  margin-bottom: 0;
  display: flex;
}

.service-hero-heading{
  color: #fff;
  text-align: center;
  letter-spacing: -1px;
  max-width: 900px;
  margin-bottom: 79px;
  font-size: 120px;
  font-weight: 500;
  line-height: 1;
}


@media screen and (max-width:1024px) {
  .service-hero{
    height: 60vh;
    padding-top: 0;
    /* padding-bottom: 10vh; */
  }
  .service-hero-bg-image-wrapper{
    /* height: 60vh; */
        /* position: relative; */
  }
  .service-hero-bg-image{
    /* position: relative; */
  }
  /* .service-hero{
    height: auto;
    padding-top: 0;
    padding-bottom: 10vh;
  }
  .service-hero-bg-image-wrapper{
    height: 60vh;
        position: relative;
  }
  .service-hero-bg-image{
    position: relative;
  }
  .service-hero-bg-image-overlay{
    z-index: 2;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 52%, var(--black));
    height: 100%;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
  } */
  /* .service-hero-fg{
    justify-content: flex-end;
        padding-bottom: 0;
    
  }
  .service-hero-heading-text-container{
    align-items: flex-start;
    width: 100%;
    margin-top: -88px;
    margin-bottom: 0;
  }
  .service-hero-heading{
    text-align: left;
    margin-bottom: 49px;
    font-size: 80px;
  } */
  
}

@media screen and (max-width:767px) {
  .service-hero-bg-image-wrapper{
    /* height: 53vh; */
  }
  .service-hero{
    height: 53vh;
    padding-top: 0;
    /* padding-bottom: 10vh; */
  }
  .service-hero-bg-image-overlay{
    z-index: 2;
   
  }


}














.font

.color29 {
  color: #29487d !important;
}

.widthtext2 {
  max-width: 933px;
  width: 100%;
}
.div_card_follow {
  max-width: 374px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  /* height: 377px; */
}


.div_card_follow_step {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 20px 37px 35px 30px;
  /* flex: 1 1 17rem; */
  /* align-self: normal; */
  display: flex;
  flex-direction: column;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  max-width: 350px;
  width: 100%;

  /* align-items: center; */
}

.div_card_blogheader {
  max-width: 299px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.div_card_recent_article {
  max-width: 506px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.image_card_follow {
  max-width: 347px;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 250px;
}

/* start common Button */

.style_parent_div_btn {
  padding: 17px 31px;
}
.style_parent_div_btn_sectionyearexperience {
  max-width: 145px;
  width: 100%;
  height: 44px;
  padding: 0px 15px 0px 18px;
  
  
}
.commonbtnstyle_landing_blogs {
  /* min-height: px; */
  height: 44px;
  padding: 0px 22px;
}
.style_parent_div_btn_ourservices {
  max-width: 133px;
  height: 44px;
  width: 100%;
  /* min-height: 55px; */
  padding: 0px 15px 0px 18px;
}
.commonbtnstyle_our_blogs {
  min-height: 32.07px;
  padding: 0px 19px;
}
.btn_readmore_recentarticle_card_right_side {
  /* min-height: 24.38px; */
  padding: 0px 16px;
  width: 133px;
height: 44px;
}
.btn_readmore_recentarticle_card {
  /* min-height: 24.38px; */
  padding: 0px 6px;
  width: 91.72px;
  height: 28px;
/* height:px; */
}
.p_date_cardleft_recent {
  max-width: 134px;
  width: 100%;
}

.parent_div_common_btn {
  background: #29487d;
  border-radius: 27.5px;
  position: relative;
}
.btn_common_ {
  background: #D9DDDC ;
  border-radius: 27.5px;
  border: none !important;
  color: #FF0000 !important;
  position:relative;
  overflow:hidden;
  text-decoration:none;
  transition:.2s transform ease-in-out;
  will-change:transform;
  z-index:0;
}

.btn_common_::after{
  background-color :#FF0000;
  border-radius : 3rem;
  content: '';
  display: block;
  height: 100%;
  width :100%;
  position: absolute;
  left: 0;
  top: 0;
  transform :translate(-100%, 0) rotate(10deg);
  transform-origin :top left;
  transition: .2s transform ease-out;
  will-change: transform;
  z-index: -1;
}
.btn_common_:hover::after{

  transform:translate(0, 0);
}

.btn_common_:hover{
  border:2px solid transparent;
  color:indigo;
  transform:scale(1.05);
  will-change:transform;
  color: #ffffff !important;

}




.btn_booknow_white {
  background: #fff;
  border-radius: 27.5px;
  border: none !important;
  color: #000 !important;
  width: 180px;
  padding: 15px 15px 15px 18px !important;
}
.typo-contactus{
  font-size: 17px !important;
  font-weight: 400 !important;
  text-align: center;
}
.fixedTop{
  position: fixed !important;
  width: 100% !important;
  top: 0;
}
/* end common Button */

/* Common tabs */

.div_main_tabs_heading {
  gap: 25px;
}

.div_main_tabs_heading::-webkit-scrollbar {
  display: none;
}
/* .h4_text_tab_common {
  max-width: 120px;
  width: 100%;
} */
.div_h4_text_tab_common {
  width: 120px;
  cursor: pointer;
  
}
.h4_text_tab_common:hover{
  color: #29487d !important;  

}
.hovertabcontentnavbar:hover{
color: #29487d !important;  

}

.h4_tabs_mage_menu:hover{
color: #29487d !important;  
}

/* end Commmon tabs */

/*  */

.commonbtnsubmit {
  border-radius: 25px;
}

/*  */
.landing-container{
  background: #D9DDDC;
}

@media screen and (min-width: 300px) and (max-width: 1920px) {
  .div_container_wrapper {
    max-width: 1920px !important;
    padding: 0px !important;
  }
}

@media screen and (max-width: 1500px) {
  .div_commonwrapper {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .div_main_tabs_heading {
    width: 850px;
    overflow-x: scroll;
  }
  .div_main_tabs_heading::-webkit-scrollbar {
    display: none;
  }
  .p_date_cardleft_recent {
    max-width: 70px;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .div_commonwrapper {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

@media screen and (max-width: 1024px) {
  .div_main_tabs_heading {
    width: 550px;
  }
}

@media screen and (max-width: 900px) {
  .div_commonwrapper {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .div_main_tabs_heading {
    gap: 15px;
  }
}

@media screen and (max-width: 600px) {
  .div_main_tabs_heading {
    max-width: 515px;
    width: 100%;
    overflow-x: scroll;
  }
  .div_commonwrapper {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .div_card_ourblogs_landing{
    height: 300px;
  }
  .slick-dots li button:before {
    width: 10px !important;
    height: 10px !important;
    line-height: 16px !important;
  }
  .slick-dots li {
    width: 10px !important;
    height: 10px !important;
  }
  .slick-dots li button {
    width: 10px !important;
    height: 10px !important;
  }
}